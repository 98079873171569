<template>
    <div>
        <h2>{{ $t("compta.comptes_comptables_payment") }}</h2>
        <div class="box">
            <CustomTable
                id_table="accounting_payment_account"
                :items="accounting_payment_account"
                :busy.sync="table_busy"
                primaryKey="accountingpaymentaccount_id"
                :hide_if_empty="true"
            />
            <AccountingPaymentAccountAjout :accountingplan_id="accountingplan_id" />
        </div>
    </div>
</template>

<script type="text/javascript">
    import TableAction from "@/mixins/TableAction.js"
    import Accounting from "@/mixins/Accounting.js"
    import AccountingAccount from "@/mixins/AccountingAccount.js"
    import Navigation from "@/mixins/Navigation.js"
    
    export default {
        name: "AccountingAccount",
        mixins: [TableAction, Accounting, AccountingAccount, Navigation],
        props: ['accountingplan_id'],
        data () {
            return {
                accounting_payment_account: null,
                table_busy: true,
                events_tab: {
                    
                }
            }
        },
        mounted() {
            this.init_component()
        },
        methods: {
            async init_component() {
                this.loadAccountingAccountListe()
            },
            async loadAccountingAccountListe() {
                this.table_busy = true
                this.accounting_payment_account = await this.loadAccountingPaymentAccounts(this.accountingplan_id)
                this.table_busy = false
            }
        },
        computed: {
            
        },
        components: {
            CustomTable: () => import('GroomyRoot/components/Table/CustomTable'),
            AccountingPaymentAccountAjout: () => import('@/components/Accounting/AccountingPaymentAccountAjout'),
        }
    }
</script>
